import dispatchEvent from "./dispatch-event";

const bag = document.querySelector('[data-cart-count]') ?? false;

const updateBagCount = () => {
  if (!bag) {
    return;
  }

  fetch(`${window.Shopify.routes.root}cart.js`)
    .then(response => response.json())
    .then(data => {
      bag.innerHTML = data.item_count;
      if (data.item_count > 0) {
        bag.classList.remove('opacity-0');
        bag.classList.add('opacity-100');
      } else if (data.item_count === 0) {
        bag.classList.remove('opacity-100');
        bag.classList.add('opacity-0');
      }
      window.cart_items = data.items;
      dispatchEvent('cart:updated-bag-count', document);
    });
};

export default updateBagCount;
